/*
 Confirm dialog
 */
import * as Turbo from '@hotwired/turbo'
import Dialog from 'libs/dialog'
import { i18n } from 'locales/i18n'

export default class Confirm extends Dialog {
  constructor(message, options = {}) {
    super(message, options)

    this.cancelLabel = options.cancelLabel || i18n.t('buttons.cancel')
    this.continueLabel = options.continueLabel || i18n.t('buttons.continue')
    this.putForwardCancellation = options.putForwardCancellation || false
  }

  static railsSetup() {
    Turbo.config.forms.confirm = (message, element) => {
      const options = element.dataset.confirm ? JSON.parse(element.dataset.confirm) : {}
      const confirm = new Confirm(message, options)
      return confirm.show()
    }
  }

  templateContent() {
    let continueClass = 'button-secondary'
    let cancelClass = 'button-secondary-outline'
    let footerClass = ''

    if (this.putForwardCancellation) {
      [continueClass, cancelClass] = [cancelClass, continueClass]
      footerClass = 'flex-row-reverse'
    }

    return `
      ${this.templateTitle()}
      <div data-dialog-message></div>
      <modal-footer class="${footerClass}">
        <button data-dialog-resolve="true" class="button ${continueClass}">${this.continueLabel}</button>
        <button data-dialog-resolve="false" class="button ${cancelClass}">${this.cancelLabel}</button>
      </modal-footer>
    `
  }
}

import { Controller } from '@hotwired/stimulus'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { MeConfig } from 'config.js'

export default class extends Controller {
  connect() {
    const footer = document.querySelector('footer:not([bfp])')
    if (!footer) return

    const fpPromise = FingerprintJS.load({
      apiKey: MeConfig.fingerprintjs.publicKey,
      endpoint: [
        MeConfig.fingerprintjs.endpoint,
        FingerprintJS.defaultEndpoint
      ],
      scriptUrlPattern: [
        `${MeConfig.fingerprintjs.endpoint}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
        FingerprintJS.defaultScriptUrlPattern
      ],
      region: 'eu'
    })

    fpPromise
      .then(fp => fp.get())
      .then(result => {

        const seed = Math.random().toString(16).substring(2, 7)
        const img = document.createElement('img')
        img.src = `/images/bfp-${result.visitorId}-${seed}.png`
        footer.appendChild(img)
        footer.toggleAttribute('bfp', true)
      })
  }
}

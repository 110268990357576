/*
 Dialog
 */
import { i18n } from 'locales/i18n'

export default class Dialog {
  constructor(message, options = {}) {
    this.message = message
    this.title = options.title
    this.okLabel = options.okLabel || i18n.t('buttons.ok')
    this.closeResolveValue = (options.doNotResolveOnClose) ? 'close' : 'false'
  }

  show() {
    this.buildNode()

    document.body.appendChild(this.element)

    return new Promise((resolve, reject) => {
      this.element.querySelectorAll('[data-dialog-resolve]').forEach((button) => {
        button.addEventListener('click', (e) => {
          this.remove()
          if (e.currentTarget.dataset.dialogResolve === 'close') return

          resolve(e.target.dataset.dialogResolve === 'true')
        }, { once: true })
      })
    })
  }

  remove() {
    this.element.remove()
  }

  buildNode() {
    const placeholder = document.createElement('div')
    placeholder.innerHTML = this.template()
    if (this.message instanceof DocumentFragment)
      placeholder.querySelector('[data-dialog-message]').replaceWith(this.message)
    else
      placeholder.querySelector('[data-dialog-message]').innerHTML = this.message

    this.element = placeholder.firstElementChild
  }

  template() {
    return `
      <modal-slot>
        <modal-dialog>
          <button data-dialog-resolve="${this.closeResolveValue}" class="button-close"><font-icon class="cross"></font-icon></button>
          <modal-body data-modal="content">
            ${this.templateContent()}
          </modal-body>
        </modal-dialog>
        <modal-overlay data-dialog-resolve="${this.closeResolveValue}"></modal-overlay>
      </modal-slot>
    `
  }

  templateContent() {
    return `
      ${this.templateTitle()}
      <div data-dialog-message></div>
      <modal-footer>
        <button data-dialog-resolve="true" class="button button-secondary">${this.okLabel}</button>
      </modal-footer>
    `
  }

  templateTitle() {
    return `<h1>${this.title || ''}</h1>`
  }
}

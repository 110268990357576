import { Controller } from '@hotwired/stimulus'
import Confirm from 'libs/confirm'

export default class extends Controller {
  static values = { statusUrl: String, trustpilotUrl: String, supportUrl: String }

  connect() {
    const confirm = new Confirm(
      I18n.t('js.trustpilot.confirm'),
      {
        continueLabel: I18n.t('common.t_yes'),
        cancelLabel: I18n.t('common.t_no'),
        doNotResolveOnClose: true
      }
    )

    confirm.show()?.then((yes) => {
      this.registerChoice(yes)

      if (yes)
        window.open(this.trustpilotUrlValue, '_blank').focus()
      else
        Turbo.visit(this.supportUrlValue)
    })
  }

  registerChoice(yes) {
    fetch(this.statusUrlValue, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify({ trustpilot_evaluation: (yes) ? 'rated' : 'disliked' })
    })
  }
}


import { pinia } from 'stores'
import { createApp } from 'vue'
import { useCommonStore } from 'stores/common_store'
import { useNotificationsStore } from 'stores/notifications_store'

export const MeConfig = {
  locale: "fr",
  stripe: {
    publicKey: "pk_live_51LDrHeF8hBqvIjcaDiZFtm5ot9b20E1SBpnil3cjqLFLkOGTnr8Cw2HQM9Q3TavmyPgrfPYsMLgqReHysGImP5Ho000FPBJ5uz"
  },
  fingerprintjs: {
    endpoint: "https://fp.nouslib.com",
    publicKey: "NYlcg73RtA5vmu16TGjp"
  }
}

export const LeafletConfig = {
  markerIconUrl: "/assets/v21/icons/pin-pink-d0dabc30b0497239a0a0df4853db924b549c79be1606e8d769c408577ef087bd.svg"
}

export function setup() {
  I18n.defaultLocale = "fr"

  // Load config
  fetch('/app_config.json', { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
    .then(response => response.json())
    .then(appConfig => {
      initStores(appConfig)
    })

  document.addEventListener('contextmenu', event => event.preventDefault())
}

function initStores(appConfig) {
  const app = createApp({})
  app.use(pinia)

  const common = useCommonStore()
  common.currentUser = appConfig.config.current_user
  common.device = appConfig.config.device

  const notifications = useNotificationsStore()
  notifications.pmessages = appConfig.stats.new_pmessages_count
  notifications.kisses = appConfig.stats.new_kisses_count
  notifications.formViews = appConfig.stats.new_form_views_count
  notifications.friendships = appConfig.stats.new_friends_count
  notifications.testimonials = appConfig.stats.new_testimonials_count
  notifications.socialNotifications = appConfig.stats.new_social_notifications_count
  notifications.events = appConfig.stats.events_count
}
